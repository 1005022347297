import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Utilities = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.introduction, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        {data?.stockage && (
          <>
            <h6>Stockage (Fiche Technique)</h6>
            <br />
            <div className={"row col-md"}>
              <label>Moyens de manutention : </label>
              {data.stockage.stockageItemStock?.manutention}
            </div>
            <div className={"row col-md"}>
              <label>Gestion des stocks: </label>
              {data.stockage.stockageItemStock?.mode_gestion}
            </div>
          </>
        )}
        {data?.stockage?.stockageList && (
          <>
            <h6>Stockage List(Fiche Technique)</h6>
            <br />
            {data.stockage?.stockageList.map((item) => (
              <>
                <h6>Item numéro {item.id} </h6>
                <div className={"row col-md"}>
                  <label>Type : </label>
                  {item?.type}
                </div>
                <div className="row col-md">
                  <label>Désignation: </label>
                  {item?.Désignation}
                </div>

                <div className="row col-md">
                  <label>Batiments: </label>
                  {item?.batiments}
                </div>
                <div className={"row col-md"}>
                  <label>Nature : </label>
                  {item?.nature}
                </div>
                <div className="row col-md">
                  <label>Mode de stockage: </label>
                  {item?.mode_stockage}
                </div>
              </>
            ))}
          </>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default Utilities;
