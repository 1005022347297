import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const PAE = ({ data, active, ...props }) => {
    console.log("data===>",Object.keys(data),data.situation,data.site)
  const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";

  if (data)
    return (
      <>
        
         <h6>PROCESS ET EQUIPEMTS</h6>
        <br />
        <h6> CAPACITE DE PRODUCTION </h6>
       
        <div className={"row col-md-6"}>
        <label>Capacité journaliére : </label>
        {data.PAE.capaciteJournaliere}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacité annuelle : </label>
        {data.PAE.capaciteAnnuelle}
      </div>
      <h6>ACTIVITÉS EXERCÉES </h6>
        <div className={"row col-md-6"}>
            <label>Activité : </label>
            {data.PAE.acivite}
        </div>
        <div className={"row col-md-6"}>
        <label>Durée d'activité : </label>
            {data.PAE.dureeActivite}
         </div>
         <div className={"row col-md-6"}>
        <label>Saison du Pic de production : </label>
            {data.PAE.saisonProduction}
         </div>
         <div className={"row col-md-6"}>
            <label>Période de mise à la vente  : </label>
            {data.PAE.periodeMiseVente}
         </div>
         <div className={"row col-md-6"}>
            <label>Produits finis  : </label>
            {data.PAE.produitsFinis}
         </div>
         <div className={"row col-md-6"}>
            <label> Projets d’extension  : </label>
            {data.PAE.projetExtension}
         </div>
         <h6>   MATIERES PREMIERES </h6>
         <div className={"row col-md-6"}>
            <label> Matière  : </label>
            {data.PAE.Matière}
         </div>
         <div className={"row col-md-6"}>
            <label> Nature  : </label>
            {data.PAE.Nature}
         </div>
         <div className={"row col-md-6"}>
            <label> Forme  : </label>
            {data.PAE.Forme}
         </div>
         <div className={"row col-md-6"}>
            <label> Fournisseur (Pays)  : </label>
            {data.PAE.Fournisseur}
         </div>
        <h6>PROCESSUS DE PRODUCTION </h6>
        <div className={"row col-md-6"}>
            <label> Nombre de ligne de production : </label>
            {data.PAE.ligneProduction}
         </div>
         <h6>CONTROLE DE QUALITE </h6>
         <div className={"row col-md-6"}>
            <label> Laboratoire : </label>
            {data.PAE.laboratoire}
         </div>
         <div className={"row col-md-6"}>
            <label> Les différents contrôles  : </label>
            {data.PAE.controleQualite}
         </div>
         <div className={"row col-md-6"}>
            <label> Prélévement : </label>
            {data.PAE.Prélévement}
         </div>


        <h6>PRINCIPAUX EQUIPEMENTS </h6>
        <div className={"row col-md-6"}>
            <label> Equipements : </label>
            {data.PAE.Equipements}
         </div>
         <div className={"row col-md-6"}>
            <label> Marques : </label>
            {data.PAE.Marques}
         </div>
         <div className={"row col-md-6"}>
            <label> N° de série : </label>
            {data.PAE.numeroSerie}
         </div>
         <div className={"row col-md-6"}>
            <label> Année d’acquisition : </label>
            {data.PAE.anneeAcquisition}
         </div>
         <div className={"row col-md-6"}>
            <label> Valeurs d’acquisition DA : </label>
            {data.PAE.valeurAcquisition}
         </div>


      </>
    );
  else return <>Didn't clock in</>;
};

export default PAE;
