import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Utilities = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.introduction, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        {data?.construction?.constructionsuperficie && (
          <>
            <h6>Construction (Fiche Technique)</h6>
            <br />
            <div className={"row col-md"}>
              <label>Superficie totale : </label>
              {data.construction.constructionsuperficie?.superficieTotale}
            </div>
            <div className={"row col-md"}>
              <label>Superficie batie: </label>
              {data.construction.constructionsuperficie?.superficieBatie}
            </div>
            <div className={"row col-md"}>
              <label>moyens de drainage : </label>
              {data.construction.constructionsuperficie?.drainage}
            </div>
          </>
        )}
        {data?.construction?.constructionList && (
          <>
            <h6>Construction List(Fiche Technique)</h6>
            <br />
            {data.construction.constructionList.map((item) => (
              <>
                <h6>Item numéro {item.id} </h6>

                <div className="row col-md">
                  <label>Batiments: </label>
                  {item?.type}
                </div>

                <div className="row col-md">
                  <label>Superficie (m² ): </label>
                  {item?.superficie}
                </div>
                <div className={"row col-md"}>
                  <label>Niveaux : </label>
                  {item?.niveau}
                </div>
                <div className="row col-md">
                  <label>Murs: </label>
                  {item?.murs}
                </div>
                <div className="row col-md">
                  <label>Ossature: </label>
                  {item?.ossature}
                </div>
                <div className="row col-md">
                  <label>Couverture: </label>
                  {item?.couverture}
                </div>
                <div className="row col-md">
                  <label>Etanchéite: </label>
                  {item?.etanchéite}
                </div>
              </>
            ))}
          </>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default Utilities;
