import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const surete = ({ data, active, ...props }) => {
    console.log("data===>",Object.keys(data),data.surete,data.site)
  const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";

  if (data)
    return (
      <>
        
         <h6>Sureté </h6>
        <br />
        <h6> Accés</h6>
       
      <div className={"row col-md-6"}>
        <label>Nombre d’accès   :  </label>
        {data.surete.nombreAcces}
      </div>

 
      <div className={"row col-md-6"}>
        <label>Situation  :  </label>
        {data.surete.situation}
      </div>
      <div className={"row col-md-6"}>
        <label>Accès principal :  </label>
        {data.surete.accessPrincipal}
      </div> 
      <div className={"row col-md-6"}>
        <label>Accès secoundaire :  </label>
        {data.surete.accessSecondaire}
      </div>
      <h6>Cloture </h6>
            
       
      <div className={"row col-md-6"}>
        <label>Type de mur :  </label>
        {data.surete.typeMur}
      </div> 
      <div className={"row col-md-6"}>
        <label>Hauteur : </label>
        {data.surete.hauteurMur}
      </div>
      <div className={"row col-md-6"}>
        <label>Fils barbelés : </label>
        {data.surete.filsbarbeles}
      </div>
      <h6> Gardiennage </h6>
      <div className={"row col-md-6"}>
        <label>Equipe interne : </label>
        {data.surete.EquipeInterne}
      </div>
      <div className={"row col-md-6"}>
        <label>Structure : </label>
        {data.surete.Structure}
      </div>
      <div className={"row col-md-6"}>
        <label>Nombre : </label>
        {data.surete.Nombre}
      </div>
      <div className={"row col-md-6"}>
        <label>Systéme de travail : </label>
        {data.surete.Systéme_travail}
      </div>
      
      <div className={"row col-md-6"}>
        <label>Supervision: </label>
        {data.surete.Supervision}
      </div>
      <div className={"row col-md-6"}>
        <label>Soustraitance : </label>
        {data.surete.Soustraitance}
      </div>
      <div className={"row col-md-6"}>
        <label>Nom du sous traitant : </label>
        {data.surete.nomSousTraitant}
      </div>
      <div className={"row col-md-6"}>
        <label>Nombre : </label>
        {data.surete.NombreSousTraitant}
      </div>
     
      <div className={"row col-md-6"}>
        <label>Supervision : </label>
        {data.surete.Supervisiongardiennage}
      </div>
      <h6> Contrôle des mouvements </h6>
       
      
      <div className={"row col-md-6"}>
        <label> Contrôle des mouvements : </label>
        {data.surete.controlemouvement}
      </div>
     <h6> Caméras </h6>
       <div className={"row col-md-6"}>
        <label>Sytéme de telesurveillance  : </label>
        {data.surete.telesurveillance}
      </div>
    
   
      <div className={"row col-md-6"}>
        <label>Nombre des caméras : </label>
        {data.surete.NombreCamera}
      </div>
        <div className={"row col-md-6"}>
        <label>Type de caméras : </label>
        {data.surete.typeCamera}
      </div>
      <div className={"row col-md-6"}>
        <label>Emplacement  : </label>
        {data.surete.EmplacementCamera}
      </div>
      <div className={"row col-md-6"}>
        <label>Durée d'enregistrement  : </label>
        {data.surete.dureeEnregistrement}
      </div>
      <div className={"row col-md-6"}>
        <label> Supervision  : </label>
        {data.surete.SupervisionCamera}
      </div>
      <div className={"row col-md-6"}>
        <label> Emplacement de la salle de supervision  : </label>
        {data.surete.emplacementSupervision}
      </div>
     
      
    <h6>Système anti-intrusion </h6>

   
      <div className={"row col-md-6"}>
        <label>Système anti-intrusion  : </label>
        {data.surete.antiIntrusion}
      </div>

      <div className={"row col-md-6"}>
        <label>Existence  : </label>
        {data.surete.systemeAntiIntrusion}
      </div>

      <div className={"row col-md-6"}>
        <label>Etat  : </label>
        {data.surete.Etat}
      </div>

      <div className={"row col-md-6"}>
        <label>type  : </label>
        {data.surete.typeDropDown}
      </div>
      <div className={"row col-md-6"}>
        <label>Frequence  : </label>
        {data.surete.frequence}
      </div>
      <div className={"row col-md-6"}>
        <label>Emplacement  : </label>
        {data.surete.Emplacement}
      </div>
     
      <div className={"row col-md-6"}>
        <label>Vérifié  : </label>
        {data.surete.Vérifié}
      </div>
      </>
    );
  else return <>Didn't clock in</>;
};

export default surete;
