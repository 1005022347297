import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Utilities = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.introduction, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        {data?.Utilites?.transformateurData && (
          <>
            <h6>Transformateur (Fiche Technique)</h6>
            <br />
            <div className={"row col-md"}>
              <label>Nombre de lignes : </label>
              {data.Utilites.transformateurData?.transformateur_nbl}
            </div>
            <div className={"row col-md"}>
              <label>Tension électrique : </label>
              {data.Utilites.transformateurData?.transformateur_tensionElectrique}
            </div>
            <div className={"row col-md"}>
              <label>Nombre de transformateurs : </label>
              {data.Utilites.transformateurData?.transformateur_Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Locaux des transformateurs : </label>
              {data.Utilites.transformateurData?.transformateur_locaux}
            </div>
            <div className={"row col-md"}>
              <label>Verouillage des locaux : </label>
              {data.Utilites.transformateurData?.transformateur_verouillage_locaux}
            </div>

            <div className={"row col-md"}>
              <label>Accés : </label>
              {data.Utilites.transformateurData?.transformateur_accés}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.transformateurData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.transformateurData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.transformateurData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.transformateurData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Backup en cas de coupures électriques : </label>
              {data.Utilites.transformateurData?.backup_coupures_electriques}
            </div>
          </>
        )}
        {data?.Utilites?.Stabilisateur && (
          <>
            <h6>Stabilisateur (Fiche Technique)</h6>
            <br />
            <div className={"row col-md"}>
              <label>Les équipements reliés au stabilisateur : </label>
              {data.Utilites.Stabilisateur?.equipements_relies_stabilisateur}
            </div>
            <div className={"row col-md"}>
              <label>nombre de stabilisateur : </label>
              {data.Utilites.Stabilisateur?.nombre_stabilisateur}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.Stabilisateur?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.Stabilisateur?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.Stabilisateur?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série : </label>
              {data.Utilites.Stabilisateur?.numero_serie}
            </div>
          </>
        )}
        <h6>Centrale électrique </h6>
        {data?.Utilites?.TurbogénérateursData && (
          <>
            <h6> Turbogénérateurs</h6>
            <br />
            <div className={"row col-md"}>
              <label>type : </label>
              {data.Utilites.TurbogénérateursData?.type}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.TurbogénérateursData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>vitesse : </label>
              {data.Utilites.TurbogénérateursData?.Vitesse}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.TurbogénérateursData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.TurbogénérateursData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.TurbogénérateursData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Nombre d'étage : </label>
              {data.Utilites.TurbogénérateursData?.Nombre_etage}
            </div>
          </>
        )}
        {data?.Utilites?.ElévateursData && (
          <>
            <h6> Elévateurs </h6>

            <div className={"row col-md"}>
              <label>Nombre de Transformateurs élévateurs : </label>
              {data.Utilites.ElévateursData?.transformateur_elevateurs}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.ElévateursData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.ElévateursData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.ElévateursData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.ElévateursData?.numero_serie}
            </div>
          </>
        )}
        {data?.Utilites.transformateursAbaisseursTensionData && (
          <>
            <h6> transformateurs abaisseurs </h6>

            <div className={"row col-md"}>
              <label>Nombres transformateurs abaisseurs de tension : </label>
              {
                data.Utilites.transformateursAbaisseursTensionData
                  ?.nombre_transformateur_abaisseurs_tension
              }
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.transformateursAbaisseursTensionData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.transformateursAbaisseursTensionData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.transformateursAbaisseursTensionData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.transformateursAbaisseursTensionData?.numero_serie}
            </div>
          </>
        )}
        {data?.Utilites?.TgbtData && (
          <>
            <h6> ARMOIRES ELECTRIQUES /TGBT </h6>

            <div className={"row col-md"}>
              <label>Installation des armoires : </label>
              {data.Utilites.TgbtData?.installation_aromires}
            </div>
            <div className={"row col-md"}>
              <label>Passage des câbles : </label>
              {data.Utilites.TgbtData?.passage_cables}
            </div>
          </>
        )}
        {data?.Utilites?.GroupeElectriqueData && (
          <>
            <h6> Groupe électrogène </h6>

            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.Utilites.GroupeElectriqueData?.centre_electrique_nb_turbogénérateurs}
            </div>
            <div className={"row col-md"}>
              <label>Basculement : </label>
              {data.Utilites.GroupeElectriqueData?.groupe_electorgene_basculement}
            </div>

            <div className={"row col-md"}>
              <label>Temps de basculement : </label>
              {data.Utilites.GroupeElectriqueData?.temps_de_basculement}
            </div>
            <div className={"row col-md"}>
              <label>Equipements reliés GE : </label>
              {data.Utilites.GroupeElectriqueData?.groupe_electorgene_equipements}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.GroupeElectriqueData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.GroupeElectriqueData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.GroupeElectriqueData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Reserve de gasoil : </label>
              {data.Utilites.GroupeElectriqueData?.groupe_electorgene_remplissage}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.GroupeElectriqueData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Remplissage de la réserve : </label>
              {data.Utilites.GroupeElectriqueData?.reserve_de_gasoil}
            </div>
            <div className={"row col-md"}>
              <label>Tests de fonctionnement : </label>
              {data.Utilites.GroupeElectriqueData?.groupe_electorgene_testFonctionement}
            </div>
          </>
        )}
        {data?.Utilites?.GasoilData && (
          <>
            <h6> Gasoil </h6>
            <div className={"row col-md"}>
              <label>type : </label>
              {data.Utilites.GasoilData?.gasoil}
            </div>
            <div className={"row col-md"}>
              <label>Capacité : </label>
              {data.Utilites.GasoilData?.Capacité}
            </div>
            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.GasoilData?.Utilisation}
            </div>

            <div className={"row col-md"}>
              <label>Mode stockage : </label>
              {data.Utilites.GasoilData?.gasoil_mode_stockage}
            </div>
            <div className={"row col-md"}>
              <label>Implantation de la réserve : </label>
              {data.Utilites.GasoilData?.implantation_de_reserve}
            </div>
          </>
        )}
        {data?.OnduleurData && (
          <>
            <h6> Onduleur </h6>
            <div className={"row col-md"}>
              <label>Nombre d'onduleurs : </label>
              {data.OnduleurData?.nombre_onduleurs}
            </div>
            <div className={"row col-md"}>
              <label>Equipements reliés aux onduleurs : </label>
              {data.OnduleurData?.Onduleur_equipement}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.OnduleurData?.Marque}
            </div>

            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.OnduleurData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.OnduleurData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.OnduleurData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Autonomie : </label>
              {data.OnduleurData?.Onduleur_autonomie}
            </div>
          </>
        )}
        {data?.Utilites?.CompresseursData && (
          <>
            <h6> Compresseurs </h6>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.Utilites.CompresseursData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.CompresseursData?.Utilisation}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.CompresseursData?.Marque}
            </div>

            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.CompresseursData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.CompresseursData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.CompresseursData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Pression : </label>
              {data.Utilites.CompresseursData?.Pression}
            </div>
          </>
        )}
        {data?.Utilites.ChaudieresData && (
          <>
            <h6> Chaudières </h6>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.Utilites.ChaudieresData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.ChaudieresData?.Utilisation}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.ChaudieresData?.Marque}
            </div>

            <div className={"row col-md"}>
              <label>Type : </label>
              {data.Utilites.ChaudieresData?.Type}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.ChaudieresData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.ChaudieresData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Pression : </label>
              {data.Utilites.ChaudieresData?.Pression}
            </div>
            <div className={"row col-md"}>
              <label> Modèle : </label>
              {data.Utilites.ChaudieresData?.Modèle}
            </div>
            <div className={"row col-md"}>
              <label> Débit de vapeur : </label>
              {data.Utilites.ChaudieresData?.debit_de_vapeur}
            </div>
            <div className={"row col-md"}>
              <label> Température : </label>
              {data.Utilites.ChaudieresData?.Température}
            </div>
          </>
        )}
        {data?.Utilites?.EauData && (
          <>
            <h6> Eau </h6>
            <div className={"row col-md"}>
              <label>Source d'eau : </label>
              {data.Utilites.EauData?.eau_source}
            </div>
            <div className={"row col-md"}>
              <label>Nombre de bâche à eau : </label>
              {data.Utilites.EauData?.eau_nb_bache}
            </div>
            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.EauData?.eau_utilisation}
            </div>

            <div className={"row col-md"}>
              <label>Capacité : </label>
              {data.Utilites.EauData?.Capacité}
            </div>
          </>
        )}
        {data?.Utilites?.StationPompageData && (
          <>
            <h6> Station de pompage </h6>
            <div className={"row col-md"}>
              <label>Type: </label>
              {data.Utilites.StationPompageData?.pompage_type}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.StationPompageData?.Marque}
            </div>
            <div className={"row col-md"}>
              <label>Modèle : </label>
              {data.Utilites.StationPompageData?.Modele}
            </div>

            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.StationPompageData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.StationPompageData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Débit : </label>
              {data.Utilites.StationPompageData?.Débit}
            </div>
          </>
        )}
        {data?.Utilites?.GazData && (
          <>
            <h6> GAZ NATUREL </h6>
            <div className={"row col-md"}>
              <label>Nombre de conduites : </label>
              {data.Utilites.GazData?.nombre_de_conduites}
            </div>
            <div className={"row col-md"}>
              <label>Positionnement des conduites : </label>
              {data.Utilites.GazData?.gaz_naturel_position}
            </div>
            <div className={"row col-md"}>
              <label>Pression : </label>
              {data.Utilites.GazData?.Pression}
            </div>

            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.GazData?.Utilisation}
            </div>
            <div className={"row col-md"}>
              <label>Implantation du poste de détente : </label>
              {data.Utilites.GazData?.implantation_detente}
            </div>
            <div className={"row col-md"}>
              <label>Vérification : </label>
              {data.Utilites.GazData?.vérification}
            </div>
            <div className={"row col-md"}>
              <label>Vanne d'arrêt d'urgence : </label>
              {data.Utilites.GazData?.vanne_arret_urgence}
            </div>
          </>
        )}
        {data?.Utilites?.SystemeRefroidissementData && (
          <>
            <h6> Système de refroidissement </h6>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.Utilites.SystemeRefroidissementData?.refroidissement_type}
            </div>
            <div className={"row col-md"}>
              <label>Utilisation : </label>
              {data.Utilites.SystemeRefroidissementData?.Utilisation}
            </div>
            <div className={"row col-md"}>
              <label>Localisation : </label>
              {data.Utilites.SystemeRefroidissementData?.Localisation}
            </div>
            <div className={"row col-md"}>
              <label>Marque : </label>
              {data.Utilites.SystemeRefroidissementData?.Marque}
            </div>

            <div className={"row col-md"}>
              <label>Numéro de série /modèle : </label>
              {data.Utilites.SystemeRefroidissementData?.numero_serie}
            </div>
            <div className={"row col-md"}>
              <label>Puissance : </label>
              {data.Utilites.SystemeRefroidissementData?.puissance}
            </div>
            <div className={"row col-md"}>
              <label>Date de fabrication : </label>
              {data.Utilites.SystemeRefroidissementData?.date_de_fabrication}
            </div>
            <div className={"row col-md"}>
              <label>Température : </label>
              {data.Utilites.SystemeRefroidissementData?.Température}
            </div>
          </>
        )}
        {data?.Utilites?.StationTraitementEauData && (
          <>
            <h6> Station de traitement des eaux </h6>
            <div className={"row col-md"}>
              <label>Nombre de station : </label>
              {data.Utilites.StationTraitementEauData?.nombre_de_station}
            </div>
            <div className={"row col-md"}>
              <label>Implantation : </label>
              {data.Utilites.StationTraitementEauData?.Implantation}
            </div>
            <div className={"row col-md"}>
              <label>Equipments alimentés par STE : </label>
              {data.Utilites.StationTraitementEauData?.equipements_alimentes_ste}
            </div>
            <div className={"row col-md"}>
              <label>Procédé : </label>
              {data.Utilites.StationTraitementEauData?.Procédé}
            </div>
          </>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default Utilities;
