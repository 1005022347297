import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const PAP = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.PAP, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        <h4>Prévenetionn et protection</h4>
        <h6>1/ Prévention</h6>
        {data?.PAP?.PreventionOrganisationData && (
          <>
            <div className={"row col-md"}>
              <label>Structure chargée de l'HSE: </label>

              {data.PAP.PreventionOrganisationData?.StructureHse}
            </div>
            <div className={"row col-md"}>
              <label>Type: </label>
              {data.PAP.PreventionOrganisationData?.uniteAdminstrative}
            </div>
            <div className={"row col-md"}>
              <label>Qualification : </label>
              {data.PAP.PreventionOrganisationData?.qualificationResponsable}
            </div>
            <div className={"row col-md"}>
              <label>Nombre / Fonction: </label>
              {data.PAP.PreventionOrganisationData?.nombreFonction}
            </div>
            <div className={"row col-md"}>
              <label>Système de travail: </label>
              {data.PAP.PreventionOrganisationData?.SystemeTravail}
            </div>
          </>
        )}

        <h6> Procédure d'urgence </h6>
        {data?.PAP?.PreventionProcedureUrgenceISData &&
        data.PAP.PreventionProcedureUrgenceISData?.issuesSecours === "Existence" ? (
          <>
            <label>
              Les procédures d’urgence appliquées au niveau du site se résument
              comme suit :
            </label>
            <br />
            <h5>Issues de secours:</h5>
            <div className={"row col-md"}>
              <label>- Nombre: </label>
              {data.PAP.PreventionProcedureUrgenceISData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Type de porte: </label>
              {data.PAP.PreventionProcedureUrgenceISData?.typePorte}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement: </label>
              {data.PAP.PreventionProcedureUrgenceISData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Accessibilité: </label>
              {data.PAP.PreventionProcedureUrgenceISData?.Accessibilité}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’issues de secours.</label>
          </>
        )}
        {data?.PAP?.PreventionProcedureUrgenceESData &&
        data.PAP.PreventionProcedureUrgenceESData?.EclairageSecours ===
          "Existence" ? (
          <>
            <h5> Éclairage de secours </h5>
            <div className={"row col-md"}>
              <label>- Alimentation:</label>
              {data.PAP.PreventionProcedureUrgenceESData?.Alimentation}
            </div>
            <div className={"row col-md"}>
              <label>- Répartition: </label>
              {data.PAP.PreventionProcedureUrgenceESData?.Répartition}
            </div>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PAP.PreventionProcedureUrgenceESData?.Etat}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’éclairage de secours.</label>
          </>
        )}
        {data?.PAP?.PreventionProcedureUrgencePRData &&
        data.PAP.PreventionProcedureUrgencePRData?.pointRassemblement ===
          "Existence" ? (
          <>
            <h5>Point de rassemblement</h5>

            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PAP.PreventionProcedureUrgencePRData?.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PAP.PreventionProcedureUrgencePRData?.Nombre}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un point de rassemblement.</label>
          </>
        )}
        {data?.PAP?.PreventionProcedureUrgencePEData &&
        data.PAP.PreventionProcedureUrgencePEData?.pointEvacuation ===
          "Existence" ? (
          <>
            <h5>Plan d’évacuation</h5>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PAP.PreventionProcedureUrgencePEData?.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Visibilité : </label>
              {data.PAP.PreventionProcedureUrgencePEData?.Visibilité}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un plan d’évacuation.</label>
          </>
        )}
        {data?.PAP?.PreventionProcedureUrgencePCData &&
        data.PAP.PreventionProcedureUrgencePCData?.pointCirculation ===
          "Existence" ? (
          <>
            <h5>Plan de circulation</h5>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PAP.PreventionProcedureUrgencePCData?.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Visibilité : </label>
              {data.PAP.PreventionProcedureUrgencePCData?.Visibilité}
            </div>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PAP.PreventionProcedureUrgencePCData?.Respect}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un plan de circulation.</label>
          </>
        )}
        <h6>Plan d’attaque du feu :</h6>
        <label>
          Plan d’attaque du feu :{" "}
          {data?.PAP?.PreventionProcedureUrgencePAData &&
            data.PAP.PreventionProcedureUrgencePAData?.planAttaque}
        </label>
        <br />
        {data?.PAP?.PreventionProcedureUrgenceAUData &&
        data.PAP.PreventionProcedureUrgenceAUData?.appelUrgence === "Existence" ? (
          <>
            <h5>Appel d’urgence</h5>
            <div className={"row col-md"}>
              <label>- Organisme : </label>
              {data.PAP.PreventionProcedureUrgenceAUData?.Organisme}
            </div>
            <div className={"row col-md"}>
              <label>- Type de ligne: </label>
              {data.PAP.PreventionProcedureUrgenceAUData?.type}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Consignes de sécurité</h6>
        {data?.PAP?.PreventionPlaquesSignaletiquesData &&
        data.PAP.PreventionPlaquesSignaletiquesData?.plaquesSignaletiques ===
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PAP.PreventionPlaquesSignaletiquesData?.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Panneaux de signalisation : </label>
              {data.PAP.PreventionPlaquesSignaletiquesData?.PanneauxSignalisation}
            </div>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PAP.PreventionPlaquesSignaletiquesData?.Application}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Protection civile</h5>
        {data?.PAP?.PreventionProtectionCivileData && (
          <>
            <div className={"row col-md"}>
              <label>- Durée : </label>
              {data.PAP.PreventionProtectionCivileData?.Durée}
            </div>
            <div className={"row col-md"}>
              <label>- Distance : </label>
              {data.PAP.PreventionProtectionCivileData?.Distance}
            </div>
          </>
        )}
        <h6>
          Ligne téléphonique :{" "}
          {data?.PAP?.PreventionLigneTelephoniqueData &&
            data.PAP.PreventionLigneTelephoniqueData?.ligne}
        </h6>
        <h5>Plan d'assistance mutuelle</h5>
        {data?.PAP?.PreventionPlanAssitanceData &&
        data.PAP.PreventionPlanAssitanceData?.planAssistance === "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Avec qui : </label>
              {data.PAP.PreventionPlanAssitanceData?.AvecQui}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Système d'Alarme</h6>
        {data?.PAP?.PreventionAlarmeData &&
        data.PAP.PreventionAlarmeData?.Alarme == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PAP.PreventionAlarmeData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Portée de l'alarme : </label>
              {data.PAP.PreventionAlarmeData?.PortééAlarme}
            </div>
            <div className={"row col-md"}>
              <label>- Déclenchement : </label>
              {data.PAP.PreventionAlarmeData?.Déclenchement}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Système de Détection Incendie</h6>
        {data?.PAP?.PreventionSystemeDetectionIncendieData &&
        data.PAP.PreventionSystemeDetectionIncendieData?.DétectionIncendie ==
          "Existence" ? (
          <>
            <h5>Equipement de contrôle et de signalisation (ECS)</h5>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PAP.PreventionEcsData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PAP.PreventionEcsData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PAP.PreventionEcsData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PAP.PreventionEcsData?.Vérifié}
            </div>

            <h5>Détecteur Automatique d'Incendie (DAI)</h5>
            <div className={"row col-md"}>
              <label>- Type : </label>
              {data.PAP.PreventionDaiData?.type}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PAP.PreventionDaiData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PAP.PreventionDaiData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PAP.PreventionDaiData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PAP.PreventionDaiData?.Vérifié}
            </div>

            <h5>Déclencheurs Manuels (DM)</h5>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PAP.PreventionDmData?.etat}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PAP.PreventionDmData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PAP.PreventionDmData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PAP.PreventionDmData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PAP.PreventionDmData?.Vérifié}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Excerice de simulation </h6>
        {data?.PAP?.PreventionExericeSimulationData &&
        data.PAP.PreventionExericeSimulationData?.ExcericeSimulation ==
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Fréquence : </label>
              {data.PAP.PreventionExericeSimulationData?.Fréquence}
            </div>
            <div className={"row col-md"}>
              <label>- Collaboration : </label>
              {data.PAP.PreventionExericeSimulationData?.Collaboration}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Permis de travail/Feu</h6>
        {data?.PAP?.PreventionPermisDeTravailData &&
        data.PAP.PreventionPermisDeTravailData?.PermisDeTravail == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PAP.PreventionPermisDeTravailData?.Application}
            </div>
            <div className={"row col-md"}>
              <label>- Travaux : </label>
              {data.PAP.PreventionPermisDeTravailData?.Travaux}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>2/ Protection</h6>
        <h5>Extincteur mobiles/fixes</h5>
        {data?.PAP?.ProtectionExtincteurMobilesFixes &&
        data.PAP.ProtectionExtincteurMobilesFixes?.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Accrochage : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Accrochage}
            </div>
            <div className={"row col-md"}>
              <label>Étiquette : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Étiquette}
            </div>
            <div className={"row col-md"}>
              <label>Plaques de numérotation extincteurs : </label>
              {
                data.PAP.ProtectionExtincteurMobilesFixes?.PlaquesNumerotationExtincteurs
              }
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Types d'extincteurs</h5>
        <h5>Eau</h5>
        {data?.PAP?.ProtectionExtincteurMobilesFixes && (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.NombreEau}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.capacitéEau}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.EmplacementEau}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.commentaireEau}
            </div>
            <h5>CO2</h5>

            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Nombreco2}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.capacitéco2}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Emplacementco2}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.commentaireco2}
            </div>

            <h5>Mousse</h5>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Nombremousse}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.capacitémousse}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Emplacementmousse}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.commentairemousse}
            </div>

            <h5>Poudre</h5>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Nombrepoudre}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.capacitépoudre}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.Emplacementpoudre}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionExtincteurMobilesFixes?.commentairepoudre}
            </div>
          </>
        )}
        <h5>Robinet incendie armé</h5>
        {data?.PAP?.ProtectionRobinetIncendieArmé &&
        data.PAP.ProtectionRobinetIncendieArmé?.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Présiser le nombre : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Source d'alimentation : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.sourceAlimentationIncendie}
            </div>

            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Etat d'entretien : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Préciser la longeur du jet : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.Commentaire}
            </div>
            <div className={"row col-md"}>
              <label>Canalisation d'eau : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.canalisationEau}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionRobinetIncendieArmé?.Vérifié}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Poteau d'incendie </h5>
        {data?.PAP?.ProtectionPoteauIncendieData &&
        data.PAP.ProtectionPoteauIncendieData?.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.PAP.ProtectionPoteauIncendieData?.Nombre}
            </div>

            <div className={"row col-md"}>
              <label>Etat d'entretien : </label>
              {data.PAP.ProtectionPoteauIncendieData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionPoteauIncendieData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionPoteauIncendieData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifie par : </label>
              {data.PAP.ProtectionPoteauIncendieData?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionPoteauIncendieData?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>STATION DE POMPAGE </h5>
        {data?.PAP?.ProtectionSPData &&
        data.PAP.ProtectionSPData?.StationPompage === "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Source d'alimentation : </label>
              {data.PAP.ProtectionSPData?.sourceAlimentation}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionSPData?.sourceAlimentation}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionSPData?.Vérifié}
            </div>
            <label>Pompe électrique </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.PAP.ProtectionPompeElectriqueData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.PAP.ProtectionPompeElectriqueData?.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.PAP.ProtectionPompeElectriqueData?.Etat}
            </div>

            <label>Pompe diesel </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.PAP.ProtectionPompedieselData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.PAP.ProtectionPompedieselData?.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.PAP.ProtectionPompedieselData?.Etat}
            </div>

            <label>Pompe Jockey </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.PAP.ProtectionPompeJockeyData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.PAP.ProtectionPompeJockeyData?.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.PAP.ProtectionPompeJockeyData?.Etat}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Extracteur d'air</h5>
        {data?.PAP?.ProtectionEAData &&
        data.PAP.ProtectionEAData?.ExtracteurAir == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.PAP.ProtectionEAData?.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.PAP.ProtectionEAData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionEAData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionEAData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionEAData?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionEAData?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Système d'extinction automatique</h5>
        {data?.PAP?.ProtectionSEAData &&
        data.PAP.ProtectionSEAData?.SystemExtinctionAutomatique == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.PAP.ProtectionSEAData?.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.PAP.ProtectionSEAData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionSEAData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionSEAData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionSEAData?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionSEAData?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Système de désenfumage</h5>
        {data?.PAP?.ProtectionSystemDesenfumageData &&
        data.PAP.ProtectionSystemDesenfumageData?.systemDesenfumage ==
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionSystemDesenfumageData?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Bacs à sable</h5>
        {data?.PAP?.ProtectionBacsSableData &&
        data.PAP.ProtectionBacsSableData?.BacsSable == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.PAP.ProtectionBacsSableData?.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.PAP.ProtectionBacsSableData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.PAP.ProtectionBacsSableData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.PAP.ProtectionBacsSableData?.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.PAP.ProtectionBacsSableData?.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default PAP;
